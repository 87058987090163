<template>
    <div id="sponsor-preview" :class="$vuetify.breakpoint.mobile ? 'mobile' : null">
        <body id="description">
            We greatly appreciate the continued support of our sponsors through
            these years. Our sponsors make it possible for us to compete in the
            American/Australian Solar Challenge.
        </body>
        <div class="button-container">
            <v-btn
                    class="button"
                    to="/sponsors"
                    rounded
                    large
                    color="#FBB514"
                >
                    <span style="margin: 5px 10px 5px 10px">OUR SPONSORS</span>
            </v-btn>
            <!-- <v-btn
                    class="button"
                    href="budget.pdf"
                    target="_blank"
                    rounded
                    large
                    color=#A51C21
                >
                    <span style="margin: 5px 10px 5px 10px">2022-2023 Budget</span>
            </v-btn> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "SponsorsPreview"
};
</script>

<style lang="scss" scoped>
#sponsor-preview {
    align-items: center;
    display: flex;
    justify-items: center;
    width: 60%;
    margin: 30px auto 30px auto;
    flex-direction: column;
}

.mobile {
    font-size: 70%;
    width: 80% !important;
}

#description {
    text-align: center;
    font-size: 2em;
}

.button-container {
    margin-top: 30px;
}

.button {
    color: white;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}
</style>
