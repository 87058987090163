<template>
    <div id="content">
        <v-tabs
            v-if="$vuetify.breakpoint.mdAndUp"
            vertical
            hide-slider
            v-model="tab"
            active-class="active-tab"
            background-color="transparent"
            color="transparent"
            class="tabs"
        >
            <v-tab
                v-for="(title, index) in tabs"
                :key="index"
                class="tab"
            >
                <div class="title">{{title}}</div>
                <div class="arrow-container">
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-arrow" d="M1.8414 3.1979L1.5762 19.6785C1.5761 20.0277 1.6676 20.3709 1.8413 20.6738C2.0151 20.9767 2.2651 21.2289 2.5666 21.4051C2.8681 21.5814 3.2104 21.6757 3.5596 21.6786C3.9089 21.6815 4.2527 21.5929 4.5571 21.4216L19.2275 12.9516C19.528 12.7737 19.7767 12.5202 19.9488 12.2163C20.1209 11.9124 20.2105 11.5688 20.2085 11.2196C20.2066 10.8703 20.1133 10.5277 19.9378 10.2258C19.7623 9.9238 19.5109 9.6731 19.2084 9.4985L4.8032 1.48791C4.5019 1.32252 4.163 1.23759 3.8193 1.24131C3.4756 1.24503 3.1386 1.33728 2.8409 1.50914C2.5432 1.68101 2.2949 1.9267 2.1198 2.22251C1.9447 2.51831 1.8488 2.85425 1.8414 3.1979Z" stroke="#A51C21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </v-tab>
            
            <v-tabs-items
                v-model="tab"
                class="tab-items"
            >
                <v-tab-item class="tab-item">
                    <v-img src="@/assets/car/aeroshell.png" class="tab-item-img" contain/>
                    <div class="text">The Aeroshell team designs and manufactures the external features and body of the car for aerodynamic performance and aesthetics. The main design goal of the aeroshell is the minimize drag coefficients through surface modeling and numerous computational fluid dynamics tests within our design software. All sections are manufactured in house and made through plug design and then casted in Kevlar composite to reduce weight and increasing strength in the final product.</div>
                </v-tab-item>
                <v-tab-item class="tab-item">
                    <v-img src="@/assets/car/chassis.png" class="tab-item-img" contain/>
                    <div class="text">The MSCP chassis features an integrated role cage and acts as the backbone of the vehicle. This collection of weldments is the major brace and structure of the car which is tested and evaluated in many crash test simulations to meet American solar car standards through Finite element analysis.</div>
                </v-tab-item>
                <v-tab-item class="tab-item">
                    <v-img src="@/assets/car/front_suspension.png" class="tab-item-img" contain/>
                    <div class="text">The double wishbone suspension design was selected due to its independence; the front uses the adjustable dampers designed for FSAE applications with twin tube damping. The double wishbone design was chosen to easily optimise the camber, toe, and scrub with the careful selection of suspension geometry determined within Performance Trends analysers.</div>
                </v-tab-item>
                <v-tab-item class="tab-item">
                    <v-img src="@/assets/car/rear_suspension.png" class="tab-item-img" contain/>
                    <div class="text">The independent trailing arm design uses adjustable dampers by Penske Racing to offset the rear weight of the batteries to improve performance. The trailing arm features a solid aluminum design with titanium damper pins to reduce unsprung mass and improve safety as determined by numerous, fatigue, design, and finite element analysis studies. The American Solar Car standard Mitsuba motor bolts directly to the end of the trailing arm to drive the car. </div>
                </v-tab-item>
                <!-- <v-tab-item class="tab-item">
                    <div class="text">PCS</div>
                </v-tab-item>
                <v-tab-item class="tab-item">
                    <div class="text">DDISP</div>
                </v-tab-item>
                <v-tab-item class="tab-item">
                    <div class="text">BMS</div>
                </v-tab-item> -->
            </v-tabs-items>
        </v-tabs>
        <Accordion v-else/>
    </div>
</template>

<script>
import Accordion from "@/components/car/Accordion.vue"

export default {
    name: "CarInfo",
    components: {
        Accordion
    },
    data() {
        return {
            tab: null,
            // tabs: ["Aeroshell", "Chassis", "Front Suspension", "Rear Suspension", "PCS", "DDISP", "BMS"]
            tabs: ["Aeroshell", "Chassis", "Front Suspension", "Rear Suspension"]
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    background-color: black;
}

.tabs {
    background: transparent;
    .tab {
        display: flex;
        border-bottom: white solid 1px;
        width: 400px;

        .title {
            flex: 1;
            color: rgb(130, 130, 130);
            font-size: 18px !important;
            text-align: left;
            font-weight: 300;
        }

        .arrow-container {
            transition-duration: 0.2s;
        }

        &:hover {
            filter: brightness(1.5)
        }
    }
}

.tab-items {
    background: #0000004f !important;
    // background: #7c36364f !important;
    color: white;

    .tab-item {
        padding: 30px 40px 30px 40px;
        display: flex;
        flex-direction: column;

        .tab-item-img {
            height: 220px;
            margin-bottom: 20px;
        }

        .text {
            text-align: center;
        }
    }
}

.active-tab {
    .title {
        color: white !important;
    }
    
    .arrow-container {
        transition-duration: 0.2s;
        transform: translateX(7px);
    }

    .svg-arrow {
        stroke: #FBB514;
    }

}
</style>