<template>
    <v-container fluid>
        <v-img src="@/assets/team/teamphoto.jpg" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)" class="landing-img">
            <div class="landing-container">
                <h1 style="font-size:50px; font-weight: bold;">EXEC TEAM</h1>
            </div>
        </v-img>
        <div class="team-columns">
            <Profile
                v-for="(member, i) in members"
                :key="i"
                :FirstName="member.firstName"
                :LastName="member.lastName"
                :Position="member.position"
                :LinkedIn="member.linkedIn"
            >
                <template v-slot:avatar>
                    <v-img class="avatar" :src="require('../assets/team/execs/'+member.src)"></v-img>
                </template>
            </Profile>
        </div>
    </v-container>
</template>

<script>
import Profile from "@/components/Profile.vue";
export default {
    name: "Team",
    components: {
        Profile,
    },
    data() {
        return {
            members: [
                { firstName: "Anand", lastName: "Jinesh", position: "Suspension & Vehicle Dynamics Manager", linkedIn: "https://www.linkedin.com/in/anandjinesh", src:"ajinesh.png"},
                { firstName: "Valerie", lastName: "Stephens", position: "Mechanical Manager", linkedIn: "https://www.linkedin.com/in/valerie-stephens/", src:"valerie stephens.jpg"},
                { firstName: "Dennis", lastName: "Cao", position: "Electrical Manager", linkedIn: "https://www.linkedin.com/in/dennis-cao-10bba3223/", src:"dennis.png"},
                { firstName: "Mark", lastName: "Chen", position: "Project Manager", linkedIn: "https://www.linkedin.com/in/mark-si-chen/", src:"Portrait - Circle - Si Chen.png"},
                { firstName: "Aryan", lastName: "Patel", position: "Mechanical lead - Chassis", linkedIn: "https://www.linkedin.com/in/aryanptl", src:"apatel.png"},
                { firstName: "Grigor", lastName: "Pahlevanyan", position: "Electrical Manager", linkedIn: "https://www.linkedin.com/in/grigorpahlevanyan", src:"Profile Pic - mcmastersolarcar.jpg"},
                { firstName: "Marsha", lastName: "Rosadi", position: "Electrical Lead - Solar Cells", linkedIn: "https://www.linkedin.com/in/marsha-rosadi/", src:"IMG_2076 - marsha rosadi.jpeg"},
                { firstName: "David", lastName: "Cosentino", position: "Electrical Lead - Battery", linkedIn: "https://www.linkedin.com/in/david-cosentino-859991253/", src:"David_Cosentino_Headshot_SolarCar - D Cos.jpg"},
                { firstName: "Alisha", lastName: "Bhogal", position: "Electrical Lead - Solar Cells", linkedIn: "https://www.linkedin.com/in/alisha-bhogal/", src:"Alisha Bhogal - Alisha Bhogal.jpg"},
                { firstName: "Isaac", lastName: "Shi", position: "Dynamics Lead", linkedIn: "https://www.linkedin.com/in/isaac-shi-8074b4205/", src:"NDAwMzk3MDI4 - Isaac Li.jpg"},
                { firstName: "Marshall", lastName: "Bahen", position: "Manufacturing Lead - Aeroshell", linkedIn: "https://www.linkedin.com/in/marshallbahen/", src:"Marshall Headshot - Marshall Bahen.png"},
                { firstName: "Mark", lastName: "Macario", position: "Suspension Lead", linkedIn: "https://www.linkedin.com/in/mjmacario/", src:"Mark Macario.jpg"},
                { firstName: "Alan", lastName: "Ding", position: "Electrical Lead - DAQ", linkedIn: "https://www.linkedin.com/in/alandingy/", src:"IMG_2005 - Alan Ding.jpeg"},
                { firstName: "Rayyan", lastName: "Amir", position: "Suspension Lead", linkedIn: "https://www.linkedin.com/in/rayyanamir/", src:"Rayyan Amir.png"},
                { firstName: "Guneev", lastName: "Arora", position: "Webdev Lead", linkedIn: "https://www.linkedin.com/in/guneevarora", src:"Guneev arora.jpg"},
                { firstName: "Sandy", lastName: "Diep", position: "Electrical Lead - Solar Cells", linkedIn: "https://www.linkedin.com/in/sandy-diep-6280a4175/", src:"sandy diep.jpg"},
                { firstName: "Annie", lastName: "Phan", position: " Electrical Lead - PMS", linkedIn: "https://www.linkedin.com/in/annie-p-b302b0233/", src:"annie pham.jpg"},
                { firstName: "Krishna", lastName: "Chauhan", position: "Strategies Lead", linkedIn: "https://www.linkedin.com/in/krishna-chauhan-79a57720b/", src:"krishna chauhan.jpg"},
                { firstName: "Nicholas", lastName: "Trimble", position: "Firmware Lead", linkedIn: "https://www.linkedin.com/in/nicholas-trimble15/", src:"nicholas trimble.jpg"},
                //moved to end
                { firstName: "Eddy", lastName: "Su", position: "Advisor", linkedIn: "https://www.linkedin.com/in/che-su-mac", src:"esu.png"},
                { firstName: "Kavish", lastName: "Wadehra", position: "Advisor", linkedIn: "https://www.linkedin.com/in/kavish-wadehra", src:"Kavish Wadehra - Kavish Wadehra.jpg"},
                { firstName: "Neil", lastName: "Lobo", position: "Advisor", linkedIn: "https://www.linkedin.com/in/neil-lobo-937094195", src:"nlobo.png"},
                { firstName: "Lewis", lastName: "Gross", position: "Advisor", linkedIn: "https://www.linkedin.com/in/lewis-gross", src:"lgross.png"},
                { firstName: "Anthony", lastName: "Seymour", position: "Advisor", linkedIn: "https://www.linkedin.com/in/anthony-matthew-seymour", src:"aseymour.png"},
            ]
        }
    }
};
Profile;
</script>

<style lang="scss" scoped>
.landing-img {
    .landing-container {
        color: white;
        width: 100%;
        height: 100%;
        // padding: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 2px 2px 2px #000000;

        h1 {
            font-weight: 400;
        }
    }
}

.team-layout {
    width: 100%;
}

.team-columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 6em;
    padding: 5em 3em 5em;
}
</style>
