<template>
    <v-container fluid>
        <v-img
            src="@/assets/sponsors/sponsors-landing.png"
            class="landing-img"
        >
            <div class="landing-container">
                <div class="title">Drive Us to Action</div>
                <div class="subtitle">Sponsor MSC</div>
                <p>
                    Our goals would not be possible without our generous sponsors.
                    The institutions below allow the students of McMaster Solar Car
                    Project to pursue learning about and promoting sustainable technology.
                    If you are interested in supporting our journey, please don't hesitate
                    to contact us at macsolarcar@gmail.com.
                </p>
                <v-btn rounded class="package-button" color=#A51C21 href="sponsorship_package.pdf" target="_blank">
                    <span class="text">SPONSORSHIP PACKAGE</span>
                </v-btn>
            </div>
        </v-img>


        <div class="subheading-container">
            <h2 class="subheading" style="color: #5f86b8;">Premium Sponsors</h2>
        </div>
        <div class="cards-container">
            <div class="cards">
                <SponsorCard
                    class="sponsor-card"
                    color=#009425
                    type="premium"
                    url="https://www.nomura.com/"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/1200px-Nomura_logo.svg.png" eager width="280px" height="50px"/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>Nomura is a global financial services group with an integrated network spanning approximately 30 countries and regions. By connecting markets East & West, we service the needs of individuals, institutions, corporates and governments through our three business divisions: Wealth Management, Investment Management and Wholesale (Global Markets and Investment Banking).</p>
                        </div>
                    </template>
                    <!-- <template v-slot:visit>
                        <a class="visit" href="https://www.nomura.com/" target="_blank" rel="noopener noreferrer">VISIT WEBSITE</a>
                    </template> -->
                </SponsorCard>

                <SponsorCard
                    class="sponsor-card"
                    color=#009425
                    type="premium"
                    url="https://archocell.ca/"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/archoshell.png" eager width="280px" height="50px"/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>At Archocell, our story originates from an unwavering passion for green energy and a shared belief in its transformative potential. We are a close-knit team of three young engineers who embarked on our journey by pioneering small-scale electrical vehicles such as e-bikes and longboards. As our expertise evolved and our horizons broadened, so did our ambitions, leading us to tackle larger and more impactful projects in the green energy sector.</p>
                        </div>
                    </template> 
                    <!-- <template v-slot:visit>
                        <a href="https://archocell.ca/" target="_blank">VISIT WEBSITE</a>
                    </template> -->
                </SponsorCard>
            
            </div>
        </div> 


        <div class="subheading-container">
            <h2 class="subheading" style="color: #4da379">Advanced Sponsors</h2>
        </div>
        <div class="cards-container">
            <div class="cards">
                <SponsorCard
                    class="sponsor-card"
                    color=#009425
                    type="advanced"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/elliottMatsuura.webp" eager/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>Our proud manufacturing history began over 100 years ago in 1905 when German Engineer, Hugo Frye, opens a precision manufacturing company in London, UK. In 1950 the B. Elliott (Canada) Ltd. opened its doors in Port Hope, Ontario. Elliott Canada started to import and distribute machine tools made in Europe. Elliott was one of the first Canadian companies to sell NC machines.</p>
                            <p>We proudly employ 90 full time staff members and sell exclusively across Canada 20 of the “best in class” portfolio of complementary machine brands. Elliott services are recognized as unequalled in the industry. Elliott provides affordable solutions to meet specific customer manufacturing needs through creative financing and dedicated support.</p>
                        </div>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                    type="advanced"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/fabris.webp" eager/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>FABRIS Inc. is a privately held, Canadian company located in Stoney Creek, Ontario on prime land in the Golden Horseshoe between Hamilton and Niagara Falls. Housed within a 80,000 square foot facility, Fabris uses the latest manufacturing techniques which allows us to be very competitive in our market segments. Our proximity to major transportation systems ensures that delivery demands can be met in a timely manner.</p>
                            <p>For over 50 years we have been perfecting and fine-tuning our products. Our accumulated knowledge and expertise in the global marketplace have allowed us to make innovative contributions to the industry, dramatically increasing efficiency and profits. Along with exemplary customer service, FABRIS has sustained growth and success by investing in expanded facilities, cutting edge equipment, technology and a highly skilled team that is fully supported by in-house training.</p>
                        </div>
                    </template>
                </SponsorCard>
                <!-- Telos-->
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                    type="advanced"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/Telos_Logo.png" eager width="280px" height="auto"/>
                    </template>
                     <template v-slot:desc>
                        <div class="desc">
                            <p>Telos Manufacturing specializes in the creative use of materials to solve challenging problems and bring ideas into reality. We offer CNC Routing, CNC Milling, CNC Plasma, Machining, Design, Fabrication, and Assembly Services.</p>
                        </div>
                    </template> 
                </SponsorCard>
                    <!-- AppliedPrecision -->
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                    type="advanced"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/AppliedPrecision.jpg" eager width="280px" height="80px"/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>Applied Precision is on the cutting edge of 3D. From troubleshooting production on small products, replicating designs and dimensions of commercial structures, to digitizing entire workplaces to create astonishing new Augmented Reality shared spaces, our technology will help you and your company hit deadlines, solve problems and save money.</p>
                        </div>
                    </template> 
                </SponsorCard>
                    <!-- Maxeon -->
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                    type="advanced"
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/maxeon-logo.svg" eager width="280px" height="70px"/>
                    </template>
                    <template v-slot:desc>
                        <div class="desc">
                            <p>We are the global leader in solar innovation. Built from 35 years of boundary-pushing solar DNA, Maxeon Solar Technologies was launched in 2020 as an independent company following its spin-off from US-based SunPower Corporation.</p>
                            <p>Maxeon Solar Technologies designs, manufactures and sells advanced SunPower and Maxeon branded solar panels to customers in more than 100 countries worldwide, through a global network of more than 1,700 sales and installation partners.</p>
                        </div>
                    </template> 
                </SponsorCard>
            </div>
        </div>
        <div class="subheading-container">
            <h2 class="subheading" style="color: #ab3a2b">Standard Sponsors</h2>
        </div>
        <div class="cards-container">
            <div class="cards">
                <SponsorCard
                    class="sponsor-card"
                    color=#5c0928
                    >
                    <template v-slot:img> 
                        <!-- keep -->
                        <img class="logo" src="@/assets/sponsors/msu.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#009425
                >
                    <template v-slot:img>
                        <img class="logo" src="@/assets/sponsors/CanadaTrust.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#009425
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img class="logo" width="150px" src="@/assets/sponsors/Alitium.svg" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#960000
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img width="250px" src="@/assets/sponsors/solidworks.svg" eager/>
                    </template>
                </SponsorCard>
                <!-- <SponsorCard
                    class="sponsor-card"
                    color=#0D439D
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/FibreLam.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0D439D
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/barrday.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#206ae6
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/eaton.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0727f7
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/airheart.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#e03c1f
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/samuel.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#a10505
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/canadian_solar.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#000000
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/nix.webp" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                >
                    <template v-slot:img>
                        <img src="@/assets/sponsors/composites.webp" eager/>
                    </template> 
                </SponsorCard> -->
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img src="@/assets/sponsors/ledab.png" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img src="@/assets/sponsors/alumco.png" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img width="250px" src="@/assets/sponsors/mitutoyo.svg" eager/>
                    </template>
                </SponsorCard>
                <SponsorCard
                    class="sponsor-card"
                    color=#0b0380
                >
                    <template v-slot:img>
                        <!-- keep -->
                        <img width="250px" src="@/assets/sponsors/bridgestone.svg" eager/>
                    </template>
                </SponsorCard>
            </div>
        </div>
    </v-container>
</template>

<script>
import SponsorCard from "@/components/SponsorCard.vue";

export default {
    name: "Sponsors",
    components: {
        SponsorCard
    }
}
</script>

<style lang="scss" scoped>
.landing-img {
    .landing-container {
        margin-top: 50px;
        margin-bottom: 50px;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 2px 2px 2px #000000;
        
        .title {
            font-weight: 400;
            font-size: 25px !important;
        }

        .subtitle {
            color: #FBB514;
            font-weight: 400;
        }

        p {
            text-align: center;
            max-width: 600px;
            width: 80%;
        }

        .package-button {
            margin-top:5%;
            height: 43px;
            .text {
                text-shadow: none;
                color:white;
                font-weight: 300;
            }
        }
    }
}

.subheading-container {
    border: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    .subheading {
        font-weight: 300;
        padding: 10px 50px 10px 50px;
        background-color: #ffffff14;
    }
}

.cards-container {
    display: flex;
    justify-content: center;
    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 1500px;
        
        .card {
            $margin: 30px;
            margin: 10px $margin 10px $margin;
        }
    }
}
</style>