<template>
  <v-app-bar
      app
      flat
      :color="this.scroll >= 65 ? '#101010' : 'transparent'"
      class="nav-bar"
    >
      <div @click="scrollToTop">
        <router-link
          to="/"
          id="logo"
          tag="img"
          src="logo.webp"
          height="50"
        />        
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="$emit('toggle')"
        id="hamburger"
      ></v-app-bar-nav-icon>
      <div v-else>
        <v-btn text class="nav-button" to="/" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">HOME</span>
        </v-btn>
        <v-btn text class="nav-button" to="/car" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">CAR</span>
        </v-btn>
        <v-btn text class="nav-button" to="/team" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">TEAM</span>
        </v-btn>
        <v-btn text class="nav-button" to="/gallery" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">GALLERY</span>
        </v-btn>
        <v-btn text class="nav-button" to="/sponsors" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">SPONSORS</span>
        </v-btn>
        <v-btn text class="nav-button" href="https://alumni.mcmaster.ca/s/1439/ifundmac/project.aspx?sid=1439&gid=1&pgid=12562&cid=22496&ecid=22496&crid=0&calpgid=3304&calcid=5363" target="_blank" color=black active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">DONATE</span>
          <span><v-icon size="small" color="white">mdi-open-in-new</v-icon></span>
        </v-btn>
        <v-btn rounded class="nav-button join-button" to="/join" color=#A51C21 active-class="active-nav" :class="this.scroll >= 65 ? 'scroll' : null" @click="scrollToTop">
          <span class="text">JOIN</span>
        </v-btn>
      </div>
    </v-app-bar>
</template>

<script>
export default {
    name: "Navbar",
    props: ["scroll"],
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      }
    },
    data() {
      return {
        drawer: false
      }
    }
}
</script>

<style lang="scss" scoped>
#hamburger {
  color: white;
  background: rgba(0, 0, 0, 0.8);
}

.nav-button {
  margin-left: 20px;
  .text {
    font-weight: 300;
    color: white;
  }
}

.active-nav {
  background-color: rgba(0, 0, 0, 0.35);
  .text {
    color: #FBB514;
  }
}

.join-button {
   .text {
     color: white;
   }
}

.scroll {
  background-color: rgba(0, 0, 0, 0.75)
}

#logo {
  cursor: pointer;
}
</style>