import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=68b13a55&scoped=true"
import script from "./Team.vue?vue&type=script&lang=js"
export * from "./Team.vue?vue&type=script&lang=js"
import style0 from "./Team.vue?vue&type=style&index=0&id=68b13a55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b13a55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
