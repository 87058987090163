<template>
    <div id="content">
        <div class="cells">
            <div
            v-for="(row, rowIndex) in cellRows" 
            :key="rowIndex"
            class="cell-hood-row"
            >
                <div
                v-for="(cell, cellIndex) in cellRows[rowIndex]"
                :key="cellIndex"
                class="cell"
                :class="`${cell.status.toLowerCase()} ${!selectedCell ? null : cell.cellNumber == selectedCell.cellNumber ? 'selected' : null}`"
                @click="selectCell(cell)"
                >
                </div>
            </div>
        </div>
        <div class="cell-info-container">
            <div class="cell-info">
                <div v-if="selectedCell">
                    <h1>Cell #{{selectedCell.cellNumber}}</h1>
                    <h3 style="margin-bottom: 30px" class="coloured" :class="selectedCell.status.toLowerCase()">{{selectedCell.status}}</h3>
                    <h3 v-if="selectedCell.status.toLowerCase() != 'available'">Adopted by: {{selectedCell.owner}}</h3>
                    <i><p v-if="selectedCell.message">" {{selectedCell.message}} "</p></i>
                </div>
                <div v-else>
                    <h1>Select a cell!</h1>
                </div>
            </div>
            <div class="update-cell-info" v-if="selectedCell && selectedCell.status.toLowerCase() != 'adopted'">
                <CellLogin 
                    v-if="! this.token"
                    :selectedCellNumber="this.selectedCell.cellNumber" 
                    @token="setToken"/>
                
                <CellUpdate 
                    v-else
                    :token="this.token"
                    @updateCell="() => { updateCells(); this.token = null}"/>
            </div>
        </div>
    </div>
</template>

<script>
import CellLogin from "@/components/car/CellLogin"
import CellUpdate from "@/components/car/CellUpdate"
export default {
    name: "AdoptACell",
    components: {
        CellLogin,
        CellUpdate
    },
    computed: {
        cellRows() {
            let out = [[]]
            for(let cell of this.cells) {
                if (out[out.length-1].length < (out.length == 1 ? 9 : out.length == 2 ? 11 : 13)) {
                    out[out.length-1].push(cell)
                } else {
                    out.push([])
                    out[out.length-1].push(cell)
                }
            }
            return out;
        }
    },
    methods: {
        selectCell(cell) {
            this.selectedCell = cell;
        },
        setToken(event) {
            this.token = event;
        },
        async updateCells() {
            const res = await fetch(new URL("https://oo25k7vsap2plccopjg4nnolzi0ukvdu.lambda-url.us-east-2.on.aws/cells"));
            this.cells = await res.json();
        }
    },
    data() {
        return {
            cells: [],
            selectedCell: null,
            token: null
        }
    },
    async mounted() {
        this.updateCells();
    }
}
</script>

<style lang="scss" scoped>
#content {
    background: #00000027;
    display: flex;
    justify-content: center;
}

.cells {
    flex: 1;
    .cell-hood-row {
        display: flex;
        justify-content: center;
        
        .cell {
            cursor: pointer;
            margin: 5px;
            width: 25px;
            height: 25px;
            background-color: white;
            transition-duration: 0.1s;
            
            &:hover {
                transform: scale(1.2);
                transition-duration: 0.2s;

            }

            &.pending {
                background-color: rgb(73, 73, 170)
            }

            &.adopted {
                background-color: rgb(167, 78, 78)
            }

            &.selected {
                transform: scale(1.2);
                transition-duration: 0.2s;
            }
        }
    }
}

.cell-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .cell-info {
        border: #FBB514 solid 2px;
        border-radius: 5px;
        flex: 3;
        width: 80%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center ;
        .coloured {
            &.pending {
                color: rgb(73, 73, 170)
            }
            
            &.adopted {
                color: rgb(167, 78, 78)
            }
        }
    }
    
    .update-cell-info {
        flex: 5;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            color: #FBB514;
        }
    }
}

</style>