<template>
    <div id="content">
        <h2>Name:</h2>
        <input class="input" type="text" name="name" id="name-input" v-model="name">
        <h2>Message:</h2>
        <input class="input" type="text" name="message" id="message-input" :maxlength="maxMessageLength" v-model="message">
        <p class="charLimit">{{ this.message.length }}/{{ maxMessageLength }}</p>
        <v-btn class="submit" @click="submit()">
            <div v-if="!loading">submit</div>
            <div v-else>
                <img src="@/assets/loading.svg" alt="">
            </div>
        </v-btn>
        <p class="error-message">{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
    name: "CellUpdate",
    props: {
        token: {
            type: String,
            required: true 
        }
    },
    data() {
        return {
            name: "",
            message: "",
            maxMessageLength: 100,
            loading: false,
            errorMessage: ""
        }
    },
    methods: {
        async submit() {
            if(this.loading) return;
            this.loading = true;
            this.errorMessage = "";
            const res = await fetch(new URL("https://oo25k7vsap2plccopjg4nnolzi0ukvdu.lambda-url.us-east-2.on.aws/cell"), {
                method: "POST",
                body: JSON.stringify({
                    token: this.token,
                    data: {
                        name: this.name,
                        description: this.message
                    }
                })
            })
            this.loading = false;
            if (res.status != 200) {
                this.errorMessage = "There was an error! Try re-logging in by refreshing the page"
                try {
                    const json = await res.json()
                    if (json.message) {
                        this.errorMessage = json.message
                    }
                } catch(err) {
                    //this is to avoid no-empty linting rule
                    this.errorMessage = "There was an error! Try re-logging in by refreshing the page"
                }
            } else {
                this.name = "";
                this.message = "";
                this.$emit("updateCell")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        color: #FBB514;
        width: 100%;
    }

    .input {
        color: white;
        border: white solid 1px;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .submit {
        width: 60%;
    }

    .error-message {
    text-align: center;
    color: rgb(167, 78, 78);
    margin-top: 5px;
}
}
</style>