<template>
    <v-container fluid>
        <v-img src="@/assets/car/car-landing.png" class="landing-img" />
        <CarIntroduction />
        <v-img src="@/assets/car/background.png">
            <div class="car-info-container">
                <CarInfo class="car-info"/>
            </div>
        </v-img>
        <div class="adopt-a-cell-container">
            <AdoptACell class="adopt-a-cell"/>
        </div>
    </v-container>
</template>

<script>
import CarIntroduction from "@/components/car/CarIntroduction";
import CarInfo from "@/components/car/CarInfo.vue"
import AdoptACell from "@/components/car/AdoptACell.vue"


export default {
    name: "OurCar",
    components: {
        CarIntroduction,
        CarInfo,
        AdoptACell
    },
};
</script>

<style lang="scss" scoped>
.landing-img {
    .landing-container {
        color: white;
        width: 100%;
        height: 100%;
        padding: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 2px 2px 2px #000000;
    }
}

.car-info-container {
    $margin: 200px;
    margin: $margin 0 $margin 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .car-info {
        width: 80%;
        max-width: 1300px;
    }
}

.adopt-a-cell-container {
    $margin: 100px;
    margin: $margin 0 $margin 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .adopt-a-cell {
        width: 80%;
        max-width: 1300px;
    }
}

</style>
