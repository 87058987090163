<template>
    <v-container fluid>
        <h1 class="heading" style="margin-top: 5rem;">Progress</h1>

        <v-row class="scroller-container" @mouseover="pauseScrolling" @mouseleave="startScrolling" justify="center">
            <div class="scroller" ref="progressScroller">
                <v-col
                    v-for="(img, index) in imgs.progress"
                    :key="index"
                    cols="12"
                    sm="4"
                    md="3"
                    lg="2"
                    class="img-container"
                >
                    <v-img
                        :src="img"
                        class="img"
                        aspect-ratio="0.6"
                        contain
                        @click="openCarousel(index, 'progress')"
                    ></v-img>
                </v-col>
            </div>
        </v-row>

        <!-- Carousel for Progress Images -->
        <div v-show="showCarousel.progress" class="carousel-container">
            <div class="carousel">
                <div class="closeCarousel">
                    <v-icon
                        large
                        color="white"
                        style="cursor:pointer"
                        @click="closeCarousel('progress')"
                    >
                        mdi-close-circle
                    </v-icon>
                </div>
                <v-carousel
                    v-model="carousel.progress"
                    hide-delimiters
                    :continuous="false"
                    height="100%"
                >
                    <v-carousel-item
                        eager
                        v-for="(img, index) in imgs.progress"
                        :key="index"
                        class="v-carousel"
                    >
                        <v-img
                            eager
                            class="carousel-img"
                            :src="img"
                            contain
                        ></v-img>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </div>

        
        <h1 class="heading">Renders</h1>

        <v-row class="scroller-container" justify="center" @mouseover="pauseScrolling" @mouseleave="startScrolling">
            <div class="scroller" ref="rendersScroller">
                <v-col
                    v-for="(img, index) in imgs.renders"
                    :key="index"
                    cols="12"
                    sm="4"
                    md="3"
                    lg="2"
                    class="img-container"
                >
                    <v-img
                        :src="img"
                        class="img"
                        aspect-ratio="0.5"
                        contain
                        @click="openCarousel(index, 'renders')"
                    ></v-img>
                </v-col>
            </div>
        </v-row>

        <div v-show="showCarousel.renders" class="carousel-container">
            <div class="carousel">
                <div class="closeCarousel">
                    <v-icon
                        large
                        color="white"
                        style="cursor:pointer"
                        @click="closeCarousel('renders')"
                    >
                        mdi-close-circle
                    </v-icon>
                </div>
                <v-carousel
                    v-model="carousel.renders"
                    hide-delimiters
                    :continuous="false"
                    height="100%"
                >
                    <v-carousel-item
                        eager
                        v-for="(img, index) in imgs.renders"
                        :key="index"
                        class="v-carousel"
                    >
                        <v-img
                            eager
                            class="carousel-img"
                            :src="img"
                            contain
                        ></v-img>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Gallery",
    data() {
        const progressImages = this.importAllImages(
            require.context('@/assets/sponsors/Progress', false, /\.(png|jpe?g|svg)$/)
        );
        const rendersImages = this.importAllImages(
            require.context('@/assets/sponsors/Renders', false, /\.(png|jpe?g|svg)$/)
        );

        return {
            showCarousel: {
                progress: false,
                renders: false
            },
            carousel: {
                progress: 0,
                renders: 0
            },
            imgs: {
                progress: progressImages,
                renders: rendersImages
            },
            scrollingInterval: null,
            scrollSpeed: 1,
            lastPaused: 0
        };
    },
    mounted() {
        this.startScrolling(); 
    },
    beforeDestroy() {
        this.pauseScrolling(); 
    },
    methods: {
        openCarousel(index, type) {
            this.carousel[type] = index;
            this.showCarousel[type] = true;
        },
        closeCarousel(type) {
            this.carousel[type] = 0;
            this.showCarousel[type] = false;
        },
        importAllImages(r) {
            return r.keys().map(r);
        },
        startScrolling() {
            this.lastPaused = 0
            this.scrollingInterval = setInterval(() => {
                const progressScroller = this.$refs.progressScroller;
                const rendersScroller = this.$refs.rendersScroller;

                if (progressScroller) {
                    progressScroller.scrollLeft += this.scrollSpeed;
                    if (progressScroller.scrollLeft + progressScroller.clientWidth >= progressScroller.scrollWidth) {
                        progressScroller.scrollLeft = 0;
                    }
                }

                if (rendersScroller) {
                    rendersScroller.scrollLeft += this.scrollSpeed;
                    if (rendersScroller.scrollLeft + rendersScroller.clientWidth >= rendersScroller.scrollWidth) {
                        rendersScroller.scrollLeft = 0;
                    }
                }
            }, 10); 
        },
        pauseScrolling() {
            clearInterval(this.scrollingInterval);
            this.scrollingInterval = null;
            let  curTime = Date.now()
            this.lastPaused = curTime
            setTimeout(() => {
                if (this.lastPaused === curTime) {
                    // this.pauseScrolling()
                    clearInterval(this.scrollingInterval);
                    this.scrollingInterval = null;
                    this.startScrolling()
                }
            },2500)
        }
    }
};
</script>

<style lang="scss" scoped>
.heading {
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 2em;
    color: #333;
}

.scroller-container {
    overflow-x: hidden; 
    white-space: nowrap;
    position: relative;
    width: 100%;
}

.scroller {
    display: flex;
    // overflow-x: hidden; 
    overflow-y: hidden;
    flex-wrap: nowrap;
    // overflow-x: auto;
    width: 80%
}

.img-container {
    flex: 0 0 auto;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    margin: 10px;
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.img {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
    object-fit: cover;
}

.carousel-container {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.carousel {
    z-index: 5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
}

.closeCarousel {
    position: absolute;
    z-index: 6;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.v-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-img {
    max-height: 80vh;
    max-width: 90vw;
}
</style>
