<template>
  <v-app>
    <Navbar :scroll="scroll" v-on:toggle="drawer = !drawer"/>
    <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndDown"
        v-model="drawer"
        color="#101010"
        app
      >
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">HOME</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/car"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">CAR</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/team"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">TEAM</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/gallery"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">GALLERY</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/sponsors"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">SPONSORS</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            href="https://alumni.mcmaster.ca/s/1439/ifundmac/project.aspx?sid=1439&gid=1&pgid=12562&cid=22496&ecid=22496&crid=0&calpgid=3304&calcid=5363"
            target="_blank"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">DONATE</span>
            <span><v-icon size="small" color="white">mdi-open-in-new</v-icon></span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="drawer-item"
            to="/join"
            active-class="active"
            text
            color="transparent"
          >
            <span class="text">JOIN</span>
          </v-btn>
        </v-list-item>
      </v-navigation-drawer>
    <v-main class="pa-0 main" >
      <keep-alive>
        <router-view class="view"></router-view>
      </keep-alive>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"

export default {
  name: 'App',
  components : {
    Navbar,
    Footer
  },
  created() {
    window.addEventListener("scroll", () => {
      this.scroll = window.scrollY
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Solar Car";
      },
    }
  },
  data() {
    return {
      scroll: 0,
      drawer: false
    }
  }
};
</script>

<style lang="scss">
// GLOBAL STYLES
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #222222
}

::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 100px;

  &:hover {
    background: #bbbbbb
  }
}
</style>

<style lang="scss" scoped>
#logo {
  cursor: pointer;
}

.drawer-item {
  .text {
    color: white;
    font-weight: 300;
  }
}

.active {
  .text {
    color: #FBB514;
  }
}

.main {
  background-color: black;
  color: white;
}

.view {
  padding: 0;
}
</style>