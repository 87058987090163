import { render, staticRenderFns } from "./CarInfo.vue?vue&type=template&id=47046d65&scoped=true"
import script from "./CarInfo.vue?vue&type=script&lang=js"
export * from "./CarInfo.vue?vue&type=script&lang=js"
import style0 from "./CarInfo.vue?vue&type=style&index=0&id=47046d65&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47046d65",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VImg,VTab,VTabItem,VTabs,VTabsItems})
