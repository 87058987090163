<template>
    <div :class="type=='standard'?'':'card'">
        <div v-if="type == 'standard'">
            <div class="img">
                <slot name="img"/>
            </div>
        </div>
        <div v-else-if="type == 'advanced'">
            <div class="advanced-container">
                <div class="top">
                        <slot name="img"/>
                </div>
                <div class="bottom">
                    <div class="desc-container">
                        <slot name="desc"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="premium-container">
                <div class="top">
                        <slot name="img"/>
                </div>
                <div class="bottom">
                    <div class="desc-container">
                        <slot name="desc"/>
                    </div>
                </div>
                <div class="bottom">
                    <img class="logo" src="@/assets/sponsors/globe.svg" height="20px" width="20px"/>
                    <a :href="url" class="visit">VISIT WEBSITE</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SponsorCard",
    props: {
        type: {
            type: String,
            default() {
                return "standard"
            },
            validator(value) {
                return ["premium", "advanced", "standard"].includes(value)
            }
        },
        url: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.desc {
    font-weight: 300;
}

.premium-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;

        .logo {
            max-width: 150px;
        }
        .desc-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            .desc {
                margin-left: 15px;
            }
        }
    }
    
    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .visit {
                color: #276ad6;
                margin-left: 10px;
                cursor: pointer;
                text-decoration: underline;
        }
    }

}

.advanced-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .bottom {
        margin-top: 10px;
    }
}
.card {
    padding: 20px;
    background-color: #ffffff14;
    max-width: 500px;
    min-height: 230px;
    display: flex;
}
</style>