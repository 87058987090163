<template>
    <div class="profile">
        <div class="avatar">
            <slot name="avatar" />
        </div>
        <body class="name-line">
            {{FirstName}} {{LastName}} <span><a :href="LinkedIn" target="_blank"><img src="@/assets/team/linkedin.svg" width="20px" height="20px"/></a></span>
        </body>
        <body>
            {{Position}}
        </body>
    </div>
</template>

<script>
export default {
    name: "Profile",
    props: ["FirstName", "LastName", "Position", "LinkedIn"],
};
</script>

<style lang="scss" scoped>
.profile {
    justify-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    vertical-align: middle;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    margin-bottom: 1em;
}

.name-line {
    display: flex;
    img {
        margin-left: 7px;
        cursor: pointer;
    }
}
</style>
