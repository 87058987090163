import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from "vue-router"

import Home from "./routes/Home.vue"
import OurCar from "./routes/OurCar.vue"
import Team from "./routes/Team.vue"
import Sponsors from "./routes/Sponsors.vue"
import JoinUs from "./routes/JoinUs.vue"
import Gallery from "./routes/Gallery.vue"
import NotFound from "./routes/NotFound.vue"

Vue.use(VueRouter);
Vue.config.productionTip = false

const routes = [
	{
		path: "/",
		meta: {title: "Solar Car Project"},
		component: Home
	},
	{
		path: "/car",
		meta: {title: "Car - Solar Car Project"},
		component: OurCar
	},
	{
		path: "/team",
		meta: {title: "Team - Solar Car Project"},
		component: Team
	},
	{
		path: "/gallery",
		meta: {title: "Gallery - Solar Car Project"},
		component: Gallery
	},
	{
		path: "/sponsors",
		meta: {title: "Sponsors - Solar Car Project"},
		component: Sponsors
	},
	{
		path: "/join",
		meta: {title: "Join - Solar Car Project"},
		component: JoinUs
	},
	{
		path: "*",
		meta: {title: "404 - Solar Car Project"},
		component: NotFound
	}
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes,
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
