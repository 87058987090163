<template>
    <div id="footer">
        <div class="socials">
            <a href="https://www.mcmaster.ca/">
                <img src="@/assets/footer/fireball.png" class="fireball socal">
            </a>
            <a href="https://www.linkedin.com/company/mcmaster-solar-car-project/">
                <img src="@/assets/footer/linkedin.svg" class="linkedin socal">
            </a>
            <a href="https://www.facebook.com/macsolarcar/">
                <img src="@/assets/footer/facebook.svg" class="facebook socal">
            </a>
            <a href="https://www.instagram.com/macsolarcar/">
                <img src="@/assets/footer/instagram.svg" class="instagram socal">
            </a>
        </div>
        <div class="contact">
            <p>McMaster University</p>
            <p>1280 Main St W, Hamilton, ON, L8S 4L8</p>
            <p>macsolarcar@gmail.com</p>
        </div>
        <v-spacer></v-spacer>
        <div class="faculty">
            <img src="@/assets/footer/faculty.png" class="faculty-img" v-if="$vuetify.breakpoint.mdAndUp">
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
#footer {
    z-index: 1;
    width: 100%;
    height: 200px;
    background-color: #000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.socials {
    display: grid;
    grid-template-areas:    "fireball linkedin"
                            "facebook instagram";
    gap: 15px;

    .socal {
        width: 30px;
        cursor: pointer;
    }
}

.contact {
    $space: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin-left: $space;
    padding-left: $space;
    border-left: white solid 1px;

    p {
        margin: 0;
    }
}

.faculty {
    img {
        height: 100px;
    }
}
</style>