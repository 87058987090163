<template>
    <div id="car-preview">
        <img
            id="car-image"
            src="@/assets/car/side-view.png"
        />
        <div id="text-block">
            <body id="car-name">
                ORIGIN
            </body>
            <h1 id="sub-text">
                Our latest innovation
            </h1>
            <v-btn
                id="button"
                to="/car"
                rounded
                large
                color="#FBB514"
            >
                <span style="margin: 0 10px 0 10px">VIEW</span>
            </v-btn>
        </div>
    </div>
</template>

<script>

export default {
    name: "CarPreview"
};
</script>

<style lang="scss" scoped>
#car-name {
    font-size: 4em;
    margin-bottom: 0em;
}

#sub-text {
    font-size: 1.5em;
    margin-top: 0em;
}

#car-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#car-image {
    display: inline-block;
    max-width: 40rem;
    margin: 5em;
}

#button {
    color: white;
    font-weight: 400;
    margin-top: 30px;
}
</style>
