<template>
    <v-container fluid class="page">
        <v-img src="@/assets/joinus/joinus.jpg" gradient="to bottom, rgba(0,0,0,.6), rgba(0,0,0,1)" style="position: relative; z-index: 0; max-height: 100%;" class="landing-img">
            <div class="landing-container">
            </div>
        </v-img>
        <!-- Signups are closed page -->
        <!-- <div class="content" :class="$vuetify.breakpoint.smAndDown ? 'mobile' : null">
            <div class="closed">
                <h1>Sorry! Signups for</h1>
                <h1>McMaster Solar Car Project</h1>
                <h1>are closed!</h1>
            </div>
            <div class="email">
                <h3>For any inquires, please</h3>
                <h3>email us!</h3>
                <a href="mailto:macsolarcar@gmail.com">
                    <v-btn
                        rounded
                        color=#FBB514
                        class="button"
                    >   
                        <v-icon left color="white">mdi-email</v-icon>
                        <span class="text">EMAIL</span>
                    </v-btn>
                </a>
            </div>
        </div> -->
        <!-- Signup google form page -->
        <div class="content" :class="$vuetify.breakpoint.smAndDown ? 'mobile' : null">
            <div class="closed">
                <h1>Signups for</h1>
                <h1>McMaster Solar Car Project</h1>
                <h1>are now <span style="color:#FBB514">OPEN!</span></h1>
            </div>
            <div class="email" style="margin-bottom: 1rem">
                <h3>Please fill in the form</h3>
                <h3>below to sign up!</h3>
                <h3 style="color: #FBB514">Due September 15th 11:59pm!</h3>
                <a href="https://forms.gle/m5q1oSZ2FzbLHoxq6/" target="_blank">
                    <v-btn
                        rounded
                        color=#FBB514
                        class="button"
                    >   
                        <v-icon left color="white">mdi-send</v-icon>
                        <span class="text">SIGN UP</span>
                    </v-btn>
                </a>
                <div style="margin-top: 50px; position: relative; overflow-y: scroll; height: 100%; padding-bottom: 200px;">
                    <h1>Our Teams</h1>
                    <div>
                        <h2 @click="toggleMechanical" style="color: #FBB514; cursor: pointer; user-select: none;">{{mechanicalDropdownText}}</h2>
                        <ul v-if="mechanicalDropdown">
                            <h2 style="margin-top: 6px">Aeroshell</h2>
                            <p>The Aeroshell team designs and manufactures the exterior of the car, which includes modelling the car body in SolidWorks, performing CFD (computational fluid dynamics) analysis, and doing resin infusions with carbon fibre. The team also designs and manufactures many interior aspects of the car, such as the dashboard, trunk, and interior walls/ panels.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Manufactured exterior aeroshell (sides, front bumper, rear bumper, bottom, and top)</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Remanufacturing top of car, designing/manufacturing interior dash/walls, and improving finish of exterior aeroshell</p>

                            <h2 style="margin-top: 6px">Chassis</h2>
                            <p>The Chassis team creates the skeleton of the car, which includes designing and implementing safety features to help shield the driver in the event of an impact using FEA analysis. The team also deals with mounting and auxiliary equipment such as harnesses, steering wheel, and seats.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Completed the full roll cage and suspension mounting points of the car</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Finalize and improve upon mounting the aeroshell and applying the interior safety cushioning</p>

                            <h2 style="margin-top: 6px">Suspension/Dynamics</h2>
                            <p>The Suspension and Dynamics team designs, analyzes, and optimizes the vehicle's suspension system to ensure top performance, safety, and integration of the suspension system with other teams. This includes designing and optimizing suspension components and geometry using FEA analysis, developing testing protocols and conducting validation tests, and fabricating/assembling the suspension components.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Manufactured and assembled the suspension system</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Modifying current suspension, remanufacturing rims, and developing tools for analysing the suspension system</p>
                        </ul>
                        <h2 @click="toggleElectrical" style="color: #FBB514; cursor: pointer; user-select: none;">{{electricalDropdownText}}</h2>
                        <ul v-if="electricalDropdown">
                            <h2 style="margin-top: 6px">Firmware</h2>
                            <p>The Firmware team designs and programs the transfer of data within the car. This includes C/C++ programming of microcontrollers to transfer CAN messages over a common bus, Python programming of a display to keep the driver updated, and design of some low-voltage electrical systems. The team also integrates and validates the functionality of the cars electrical systems.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Overhauled CAN architecture to use Arduinos instead of PIC MCUs, new Pi-based driver display, and configured battery management system</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Refine physical bus with more modular wiring, program overhauled electrical boards to communicate all necessary information, and improve display software</p>
                            <h2 style="margin-top: 6px">Strategies</h2>
                            <p>Our team codes in Python to develop simulations that optimize race strategies for our solar car. We consider factors like solar energy, terrain, and weather to select the correct torque or velocity. By refining our models, we ensure peak efficiency and performance in solar car competitions.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Completed The Basic Race Strategy Algorithm</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Modifying algorithm to accommodate traffic and integrating with Data Acquisition</p>

                            <h2 style="margin-top: 6px">DAQ</h2>
                            <p>Data Acquisition (DAQ) designs the not-so-black boxes of the car. This involves developing embedded sensor solutions, enabling live telemetry, and generating insights on timing critical data.</p>
                            <h4>2023-24 Progress:</h4>
                            <p>Developed and tested the CAN and telemetry DAQ</p>
                            <h4>2024-25 Focus:</h4>
                            <p>Testing suspension DAQ, developing new sensor modules, and designing the telemetry database and interface</p>

                            <h2 style="margin-top: 6px">PMS</h2>
                            <p>Power management works on developing and manufacturing the electrical box, integrating the high voltage and low voltage electrical systems together, while adhering to electrical safety rules. We design, test and optimize low voltage circuit boards for power distribution.</p>
                            <h4>2023-24 Progress:</h4>
                            <p>Developed and manufactured working electrical boards and architecture</p>
                            <h4>2024-25 Focus:</h4>
                            <p>Refine electrical box architecture and boards, to improve efficiency, wire management, and serviceability</p>

                            <h2 style="margin-top: 6px">Solar Cells</h2>
                            <p>The solar cells team designs the solar array configuration, the current sense board, bypass diode configuration and PCB, and installs the solar cells to the exterior. The solar cell installation includes adhesion process, wiring to PCBs and MPPTs, and collaborating with the electrical team to integrate the cells into the electrical architecture.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Designed cell configuration, bypass diode PCBs, installed MPPTs, installed hood cells, designed a prototype hall sensor board, and installed top cells onto aeroshell</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Improve hall sensor board to create a fully functional current sense board, replace all cells on top roof of vehicle, and investigate methods of stiffening cell arrays for ease of installation</p>
                        </ul>
                        <h2 @click="toggleOperations" style="color: #FBB514; cursor: pointer; user-select: none;">{{operationsDropdownText}}</h2>
                        <ul v-if="operationsDropdown">
                            <h2 style="margin-top: 6px">Finance</h2>
                            <p>The Finance team is responsible for managing the entirety of the club's finances, including reimbursements, budgeting, and bookkeeping. Using Excel and various automation tools, the team is essential in keeping the club running smoothly and needs to work closely with all other teams.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Utilized the budget and increased revenue through sponsorships.</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Streamline various processes and further increase sponsorship funding.</p>

                            <h2 style="margin-top: 6px">Outreach</h2>
                            <p>The Outreach team is responsible for reaching out to different organizations and companies to increase funding through sponsorships and donations. The team is expected to work closely with the Finance team to manage these funds effectively.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Increased the number of companies that were reached out to.</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Reach out to different companies in a more structured and standardized fashion.</p>

                            <h2 style="margin-top: 6px">Media</h2>
                            <p>The Media team is responsible for managing McMaster Solar Car's social media presence and handling any additional design work. This includes managing the team's Instagram and LinkedIn accounts through posts and messages. The team collaborates closely with all other teams to create regular updates.</p>
                            <h4>2023-2024 Progress:</h4>
                            <p>Expanded the social media presence of the Solar Car team at McMaster</p>
                            <h4>2024-2025 Focus:</h4>
                            <p>Create more impactful and frequent posts and updates and design new team merch</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "JoinUs",
    data() {
        return {
            mechanicalDropdown: false,
            electricalDropdown: false,
            operationsDropdown: false,
        }
    },
    computed: {
        mechanicalDropdownText() {
            return this.mechanicalDropdown ? "▾ Mechanical" : "▸ Mechanical";
        },
        electricalDropdownText() {
            return this.electricalDropdown ? "▾ Electrical" : "▸ Electrical";
        },
        operationsDropdownText() {
            return this.operationsDropdown ? "▾ Operations" : "▸ Operations";
        }
    },
    methods: {
        toggleMechanical() {
            this.mechanicalDropdown = !this.mechanicalDropdown;
        },
        toggleElectrical() {
            this.electricalDropdown = !this.electricalDropdown;
        },
        toggleOperations() {
            this.operationsDropdown = !this.operationsDropdown;
        }
    }
};
</script>

<style lang="scss" scoped>
.page {
    // margin-top: 180px;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.content {
    width: 50%;

    .email {
        margin-top: 50px;
    }

    z-index: 1;
    height: 100%;
}
@media (min-width: 768px) { // For non-mobile screens
    .content {
        margin-top: 20vh;
        position:absolute;
        overflow: scroll;
        z-index: 1;
        height: 100%;
    }
}

a {
    text-decoration: none;
}

.button {
    margin-top: 20px;

    .text {
        color: white;
        font-weight: 400;
    }
}

.mobile {
    font-size: 80%;
    max-width: 100vw !important;
}
</style>
