<template>
    <v-container fluid>
        <div class="home-landing">
            <v-img
                src="@/assets/home/raceway.jpg"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)"
                class="v-img"
            >
                <v-container class="title">
                    <div class="column">
                        <h1>WE ARE</h1>
                        <h1>MCMASTER SOLAR CAR PROJECT</h1>
                    </div>
                </v-container>
            </v-img>
        </div>
        <CarPreview v-if="!$vuetify.breakpoint.mobile" />
        <div class="grid-container">
            <div
                class="grid-item"
                :class="$vuetify.breakpoint.smAndDown ? 'small' : null"
            >
                <img src="@/assets/home/Screwdriver.png" class="topic-icon" />
                <br />
                <h3>Building</h3>
                <p>
                    We are currently in the process of building our 8th
                    Generation solar car designed to race in the American Solar
                    Challenge as a cruiser class vehicle.
                </p>
            </div>

            <div
                class="grid-item"
                :class="$vuetify.breakpoint.smAndDown ? 'small' : null"
            >
                <img src="@/assets/home/Programming.png" class="topic-icon" />
                <br />
                <h3>Programming</h3>
                <p>
                    We code our controllers for communications, driver's
                    steering wheel display, battery and solar power management
                    and much more.
                </p>
            </div>
            <div
                class="grid-item"
                :class="$vuetify.breakpoint.smAndDown ? 'small' : null"
            >
                <img src="@/assets/home/CAD.png" class="topic-icon" />
                <br />
                <h3>Design and CAD</h3>
                <p>
                    We create designs for our entire solar car from scratch
                    using SolidWorks for mechanical and Eagle CAD for electrical
                    and putting these designs through extensive testing.
                </p>
            </div>

            <div
                class="grid-item"
                :class="$vuetify.breakpoint.smAndDown ? 'small' : null"
            >
                <img src="@/assets/home/Handshake.png" class="topic-icon" />
                <br />
                <h3>PR and Outreach</h3>
                <p>
                    We recruit McMaster University students and sponsors to
                    build talent and share our accomplishments through various
                    social media platforms.
                </p>
            </div>
        </div>
        <SponsorsPreview />
    </v-container>
</template>

<script>
import CarPreview from "@/components/home/CarPreview.vue";
import SponsorsPreview from "@/components/home/SponsorsPreview";

export default {
    name: "Home",
    components: {
        CarPreview,
        SponsorsPreview,
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: white;
}

.grid-container {
    font-size: 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    p {
        font-size: 1.25rem;
        font-weight: 300;
    }
    h3 {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }
}

.grid-item {
    width: 400px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 15px;

    &.small {
        width: 250px;
        margin: 30px;

        p {
            font-size: 1rem;
        }
        h3 {
            font-size: 2rem;
        }
        .topic-icon {
            height: 1.5em;
            width: 1.5em;
        }
    }
}

.topic-icon {
    width: 2em;
    height: 2em;
    padding: 0;
}

.v-img {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
