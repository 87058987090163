<template>
    <div id="car-intro">
        <div id="text-block">
            <body id="car-name-header">
                PRESENTING OUR 2024 CAR
            </body>
            <h1 id="car-name">
                ORIGIN
            </h1>
        </div>
        <img
            id="car-image"
            src="@/assets/car/side-view.png"
        />
    </div>
</template>

<script>
export default {
    name: "CarIntroduction",
};
</script>

<style lang="scss" scoped>
#car-name-header {
    font-size: 1em;
    margin-bottom: 0em;
}

#car-name {
    font-size: 4em;
    margin-top: 0em;
}

#car-intro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

#car-image {
    display: inline-block;
    max-width: 500px;
    width: 90%;
}
</style>
