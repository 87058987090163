<template>
    <div id="content">
        <h1>Is this your cell?</h1>
        <p>Log-in with your One-Time-Password to submit your name & message!</p>
        <div id="login">
            <input type="password" name="otp" id="password" v-model="pass" @keyup.enter="getToken">
            <v-btn id="password-submit" @click="getToken">
                <div v-if="!loading">login</div>
                <div v-else>
                    <img src="@/assets/loading.svg" alt="">
                </div>
            </v-btn>
        </div>
        <p class="error-message">{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
    name: "CellLogin",
    props: {
        selectedCellNumber: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            pass: "",
            loading: false,
            errorMessage: "",
            errorMessageTimeout: null
        }
    },
    methods: {
        async getToken() {
            if(this.loading) return;
            this.loading = true;
            this.errorMessage = "";
            const res = await fetch(new URL("https://oo25k7vsap2plccopjg4nnolzi0ukvdu.lambda-url.us-east-2.on.aws/token"), {
                method: "POST",
                body: JSON.stringify({
                    cellNumber: this.selectedCellNumber,
                    password: this.pass
                })
            })
            this.loading = false;
            this.pass = "";
            switch (res.status) {
                case 200: {
                    const json = await res.json();
                    this.$emit("token", json.token)
                    break;
                }
                case 401:
                case 403: {
                    this.setErrorMessage("Incorrect password!")
                    break;
                }
                default:
                    this.setErrorMessage("Unexpected error! Please contact Solarcar team")
                    break;
            }
        },
        setErrorMessage(message) {
            clearTimeout(this.errorMessageTimeout);
            this.errorMessage = message
            this.this.errorMessageTimeout = setTimeout(() => {
                this.errorMessage = "";
            }, 5000)
        }
    }
}
</script>

<style lang="scss" scoped>
#content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        color: #FBB514;
    }
}

#login {
    display: flex;

    #password {
        flex: 3;
        margin-left: 10px;
        color: white;
        border: white solid 1px;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        outline: none;
        padding-left: 10px;
    }
    
    #password-submit {
        flex: 1;
        margin-right: 10px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.error-message {
    text-align: center;
    color: rgb(167, 78, 78);
    margin-top: 5px;
}
</style>